import React from 'react'
import Layout from "../components/layout"
import ContactUs from "../components/ContactUs"
import SEO from "../components/seo"

const ContactUsPage = () => {

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/contact-us",
      "@type": "webpage",
      "name": "Contact the Hubble Contacts Customer Service Team",
      "description": "The Hubble Contact customer service team is here to help you with your online prescription contact lens orders. Contact Hubble by phone 7 days a week or chat with our online team if you have any questions or concerns about your Hubble daily contact lenses order, prescription glasses, or sunglasses.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    },
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "Hubble",
      "url": "https://hubblecontacts.com",
      "sameAs": [
         "https://www.facebook.com/HubbleContacts",
         "https://www.youtube.com/@hubblecontacts",
         "https://www.instagram.com/hubblecontacts/?hl=en"
      ],
      "logo": "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg",
      "image": "https://www.hubblecontacts.com/static/b48bac9e25143697e18c581c8804d5a6/6393c/Contact-Us-Hero-Module-Desktop.webp",
      "description": "Order the more affordable daily contact lenses online. We founded Hubble with a simple mission: offer contact lens wearers the high-quality and affordable contact lenses they deserve. Order affordable daily contact lenses online with Hubble.",
      "contactPoint" : [
        { "@type" : "ContactPoint",
          "telephone" : "+1-844-334-1640",
          "contactType" : "customer service"
        }]
    }
  ]


  return (
    <Layout>
      <SEO title="Contact Hubble Contacts Customer Service"
        description="The Hubble Contact customer service team is here to help. Contact Hubble by phone 7 days a week or chat with our online team if you have any questions or concerns about your Hubble online prescription contact lens order."
        schemaMarkup={schemaMarkup}
      />
      <ContactUs/>
    </Layout>
  )
}

export default ContactUsPage
